import React from "react"
import ReactDOM from "react-dom"
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import thunk from "redux-thunk"
import logger from "redux-logger"
import rootReducers from "./store"
import App from "./App"

const reduxDevToolOptions =
  process.env.REACT_APP_BUILD_ENV === "production"
    ? {}
    : { trace: true, traceLimit: 25 }

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
      reduxDevToolOptions
    )) ||
  compose

const store = createStore(
  rootReducers,
  // @ts-ignore
  composeEnhancers(applyMiddleware(thunk, logger))
)

ReactDOM.render(
  // @ts-ignore
  <Provider store={store}>
    {/* @ts-ignore */}
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
)
