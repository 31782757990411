import axios from "axios"
import qs from "qs"

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded"
axios.defaults.withCredentials = true

const instance = axios.create({
  transformRequest: [(data) => qs.stringify(data)],
  headers: {
    HTTP_PLAT_FORM: 5,
    HTTP_PLAT: 5,
  },
})

export const aiSearchInstance = axios.create({
  headers: {
    HTTP_PLAT_FORM: 5,
    HTTP_PLAT: 5,
  },
  withCredentials: true,
})

export default instance
