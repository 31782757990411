import React, { Component } from "react"
import "./../index.scss"
import { http } from "src/utils"
import { Link } from "react-router-dom"
import { Toast } from "antd-mobile"
import icon1 from "../image/paperTranslate1.png"
import icon2 from "../image/paperTranslate2.png"
import icon3 from "../image/paperTranslate3.png"
import icon4 from "../image/1-2@2x.png"
class TeacherList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      active: "",
      isToggleOn: false,
      liClass1: "",
    }
  }
  bgChange = (index) => {
    console.log(index, 999)
    this.setState({
      active: index,
    })
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
      liClass1: prevState.isToggleOn ? "" : "active1",
    }))
  }

  componentDidMount() {
    http.get(`${API.home}/m/mobTeachers`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data,
        })
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }

  render() {
    const { active, liClass1, isToggleOn } = this.state
    return (
      <div className="paper">
        <p className="cont">论文翻译系统</p>
        <div className="teabox">
          <div className="paper-desc">
            基于GPT模型，提供高效、准确的学术论文翻译服务
          </div>
          <div className="paper-box">
            <div>
              <img width={88} src={icon1} alt="" />
              <div>1.上传论文</div>
            </div>
            <div>
              <img width={88} src={icon2} alt="" />
              <div>
                2.选择模型
                <br />
                GPT3.5/4.0
              </div>
            </div>
            <div>
              <img width={88} src={icon3} alt="" />
              <div>
                3.输出翻译
                <br />
                结果
              </div>
            </div>
          </div>
        </div>
        <div className="teabox">
          <div className="paper-desc">原文与译文左右对照，同步查询</div>
          <div className="paper-box">
            <div>
              <img width={311} src={icon4} alt="" />
            </div>
          </div>
          <div className="consult1">
            <a>请前往PC端体验</a>
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherList
