import React, { Component } from "react"
import "./../index.scss"
import { http } from "src/utils"
import { Link } from "react-router-dom"
import { Toast } from "antd-mobile"
import icon2 from "../image/3-2@2x.png"

import icon1 from "../image/3-1@2x.png"
class TeacherList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      active: "",
      isToggleOn: false,
      liClass1: "",
    }
  }
  bgChange = (index) => {
    console.log(index, 999)
    this.setState({
      active: index,
    })
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
      liClass1: prevState.isToggleOn ? "" : "active1",
    }))
  }

  componentDidMount() {
    http.get(`${API.home}/m/mobTeachers`).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data,
        })
      } else {
        Toast.info(res.data.msg, 2)
      }
    })
  }

  render() {
    const { active, liClass1, isToggleOn } = this.state
    return (
      <div className="paper">
        <p className="cont">机器人解决方案</p>
        <div className="teabox">
          <div className="paper-desc">结合企业特定的业务场景，进行定制开发</div>
          <p className="paper-p">
            基于HumanPlus二次开发出面向汽车的自动喷涂机器人
          </p>
          <div className="paper-box">
            <div>
              <img width={311} src={icon1} alt="" />
            </div>
          </div>
          <p className="paper-p">
            基于DexCap二次开发出面向工厂的自动线缆插拔机器人
          </p>
          <div className="paper-box">
            <div>
              <img width={311} src={icon2} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeacherList
